import {iconUrl} from 'data/icon'
import {ensureStatuses} from '../type'

export const AST = ensureStatuses({

	COMBUST_II: {
		id: 843,
		name: 'Combust II',
		icon: iconUrl(13214),
		duration: 30000,
	},

	COMBUST_III: {
		id: 1881,
		name: 'Combust III',
		icon: iconUrl(13248),
		duration: 30000,
	},

	ASPECTED_BENEFIC: {
		id: 835,
		name: 'Aspected Benefic',
		icon: iconUrl(13215),
		duration: 15000,
	},

	ASPECTED_HELIOS: {
		id: 836,
		name: 'Aspected Helios',
		icon: iconUrl(13216),
		duration: 15000,
	},

	ENHANCED_BENEFIC_II: {
		id: 815,
		name: 'Enhanced Benefic II',
		icon: iconUrl(13228),
		duration: 15000,
	},

	LIGHTSPEED: {
		id: 841,
		name: 'Lightspeed',
		icon: iconUrl(13220),
		duration: 15000,
	},

	SYNASTRY_SELF: {
		id: 845,
		name: 'Synastry (self)',
		icon: iconUrl(13223),
		duration: 20000,
	},

	SYNASTRY: {
		id: 846,
		name: 'Synastry',
		icon: iconUrl(13224),
		duration: 20000,
	},

	COLLECTIVE_UNCONSCIOUS: {
		id: 848,
		name: 'Collective Unconscious',
		icon: iconUrl(13227),
		duration: 18000,
	},

	COLLECTIVE_UNCONSCIOUS_MITIGATION: {
		id: 2283,
		name: 'Collective Unconscious (Mitigation)',
		icon: iconUrl(13226),
		duration: 18000,
	},

	WHEEL_OF_FORTUNE: {
		id: 956,
		name: 'Wheel Of Fortune',
		icon: iconUrl(13225),
		duration: 15000,
	},

	EARTHLY_DOMINANCE: {
		id: 1224,
		name: 'Earthly Dominance',
		icon: iconUrl(13241),
		duration: 10000,
	},

	GIANT_DOMINANCE: {
		id: 1248,
		name: 'Giant Dominance',
		icon: iconUrl(13242),
		duration: 10000,
	},

	HOROSCOPE: {
		id: 1890,
		name: 'Horoscope',
		icon: iconUrl(13251),
		duration: 10000,
	},

	HOROSCOPE_HELIOS: {
		id: 1891,
		name: 'Horoscope Helios',
		icon: iconUrl(13252),
		duration: 30000,
	},

	NEUTRAL_SECT: {
		id: 1892,
		name: 'Neutral Sect (Healing Potency)',
		icon: iconUrl(13253),
		duration: 20000,
	},

	NEUTRAL_SECT_OTHERS: {
		id: 1921,
		name: 'Neutral Sect (Barrier)',
		icon: iconUrl(13253),
		duration: 30000,
	},

	INTERSECTION: {
		id: 1889,
		name: 'Intersection',
		icon: iconUrl(13250),
		duration: 30000,
	},

	OPPOSITION: {
		id: 1879,
		name: 'Opposition',
		icon: iconUrl(13246),
		duration: 15000,
	},

	DIVINATION: {
		id: 1878,
		name: 'Divination',
		icon: iconUrl(13245),
		duration: 15000,
	},

	EXALTATION: {
		id: 2717,
		name: 'Exaltation',
		icon: iconUrl(13262),
		duration: 8000,
	},

	MACROCOSMOS: {
		id: 2718,
		name: 'Macrocosmos',
		icon: iconUrl(13263),
		duration: 15000,
	},

	// -----
	// Card buffs
	// -----

	THE_BALANCE: {
		id: 1882,
		name: 'The Balance',
		icon: iconUrl(13204),
		duration: 15000,
	},

	THE_BOLE: {
		id: 1883,
		name: 'The Bole',
		icon: iconUrl(13205),
		duration: 15000,
	},

	THE_ARROW: {
		id: 1884,
		name: 'The Arrow',
		icon: iconUrl(13206),
		duration: 15000,
	},

	THE_SPEAR: {
		id: 1885,
		name: 'The Spear',
		icon: iconUrl(13207),
		duration: 15000,
	},

	THE_EWER: {
		id: 1886,
		name: 'The Ewer',
		icon: iconUrl(13208),
		duration: 15000,
	},

	THE_SPIRE: {
		id: 1887,
		name: 'The Spire',
		icon: iconUrl(13209),
		duration: 15000,
	},

	CLARIFYING_DRAW: {
		id: 2713,
		name: 'Clarifying Draw',
		icon: iconUrl(13258),
	},

	HARMONY_OF_SPIRIT: {
		id: 2714,
		name: 'Harmony of Spirit',
		icon: iconUrl(13259),
		duration: 15000,
	},

	HARMONY_OF_BODY: {
		id: 2715,
		name: 'Harmony of Body',
		icon: iconUrl(13260),
		duration: 15000,
		speedModifier: 0.90,
	},

	HARMONY_OF_MIND: {
		id: 2716,
		name: 'Harmony of Mind',
		icon: iconUrl(13261),
		duration: 15000,
	},

	// -----
	// Cards drawn
	// -----

	BALANCE_DRAWN: {
		id: 913,
		name: 'Balance Drawn',
		icon: iconUrl(13229),
	},

	BOLE_DRAWN: {
		id: 914,
		name: 'Bole Drawn',
		icon: iconUrl(13230),
	},

	ARROW_DRAWN: {
		id: 915,
		name: 'Arrow Drawn',
		icon: iconUrl(13231),
	},

	SPEAR_DRAWN: {
		id: 916,
		name: 'Spear Drawn',
		icon: iconUrl(13232),
	},

	EWER_DRAWN: {
		id: 917,
		name: 'Ewer Drawn',
		icon: iconUrl(13233),
	},

	SPIRE_DRAWN: {
		id: 918,
		name: 'Spire Drawn',
		icon: iconUrl(13234),
	},

	LORD_OF_CROWNS_DRAWN: {
		id: 2054,
		name: 'Lord of Crowns Drawn',
		icon: iconUrl(13256),
	},

	LADY_OF_CROWNS_DRAWN: {
		id: 2055,
		name: 'Lady of Crowns Drawn',
		icon: iconUrl(13257),
	},
})
