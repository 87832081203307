import {iconUrl} from 'data/icon'
import {ensureStatuses} from '../type'

export const NIN = ensureStatuses({
	TRICK_ATTACK_VULNERABILITY_UP: {
		id: 638,
		name: 'Vulnerability Up',
		icon: iconUrl(15020),
		duration: 15000,
	},

	TRICK_ATTACK: {
		id: 3254,
		name: 'Trick Attack',
		icon: iconUrl(12918),
		duration: 15000,
	},

	MUG_VULNERABILITY_UP: {
		id: 638, // Same ID as the old Trick vuln debuff, but this one is 5 seconds longer
		name: 'Vulnerability Up',
		icon: iconUrl(15020),
		duration: 20000,
	},

	KASSATSU: {
		id: 497,
		name: 'Kassatsu',
		icon: iconUrl(12902),
		duration: 15000,
	},

	DOTON: {
		id: 501,
		name: 'Doton',
		icon: iconUrl(12904),
		duration: 24000,
	},

	SUITON: {
		id: 507,
		name: 'Suiton',
		icon: iconUrl(12906),
		duration: 20000,
	},

	TEN_CHI_JIN: {
		id: 1186,
		name: 'Ten Chi Jin',
		icon: iconUrl(12911),
		duration: 6000,
	},

	BUNSHIN: {
		id: 1954,
		name: 'Bunshin',
		icon: iconUrl(12912),
		duration: 30000,
	},

	SHADE_SHIFT: {
		id: 488,
		name: 'Shade Shift',
		icon: iconUrl(10605),
		duration: 20000,
	},

	MEISUI: {
		id: 2689,
		name: 'Meisui',
		icon: iconUrl(12914),
		duration: 30000,
	},

	RAIJU_READY: {
		id: 2690,
		name: 'Raiju Ready',
		icon: iconUrl(17597),
		duration: 30000,
	},

	PHANTOM_KAMAITACHI_READY: {
		id: 2723,
		name: 'Meisui',
		icon: iconUrl(12917),
		duration: 45000,
	},
})
