import {iconUrl} from 'data/icon'
import {Attribute} from 'event'
import {ensureActions} from '../type'

// tslint:disable:no-magic-numbers

export const BRD = ensureActions({
	// Archer
	HEAVY_SHOT: {
		id: 97,
		name: 'Heavy Shot',
		icon: iconUrl(358),
		onGcd: true,
		speedAttribute: Attribute.SKILL_SPEED,
		potency: 160,
	},
	VENOMOUS_BITE: {
		id: 100,
		name: 'Venomous Bite',
		icon: iconUrl(363),
		onGcd: true,
		speedAttribute: Attribute.SKILL_SPEED,
		potency: 15,
	},
	QUICK_NOCK: {
		id: 106,
		name: 'Quick Nock',
		icon: iconUrl(360),
		onGcd: true,
		speedAttribute: Attribute.SKILL_SPEED,
		potency: 110,
	},
	BLOODLETTER: {
		id: 110,
		name: 'Bloodletter',
		icon: iconUrl(361),
		onGcd: false,
		speedAttribute: Attribute.SKILL_SPEED,
		cooldown: 15000,
		potency: 110,
		cooldownGroup: 5,
		charges: 3,
	},
	STRAIGHT_SHOT: {
		id: 98,
		name: 'Straight Shot',
		icon: iconUrl(359),
		onGcd: true,
		speedAttribute: Attribute.SKILL_SPEED,
		potency: 200,
	},
	BARRAGE: {
		id: 107,
		name: 'Barrage',
		icon: iconUrl(353),
		onGcd: false,
		cooldown: 120000,
		statusesApplied: ['BARRAGE'],
	},
	WINDBITE: {
		id: 113,
		name: 'Windbite',
		icon: iconUrl(367),
		onGcd: true,
		speedAttribute: Attribute.SKILL_SPEED,
		potency: 20,
	},
	RAGING_STRIKES: {
		id: 101,
		name: 'Raging Strikes',
		icon: iconUrl(352),
		onGcd: false,
		cooldown: 120000,
		statusesApplied: ['RAGING_STRIKES'],
	},
	REPELLING_SHOT: {
		id: 112,
		name: 'Repelling Shot',
		icon: iconUrl(366),
		onGcd: false,
		cooldown: 30000,
	},

	// Bard
	MAGES_BALLAD: {
		id: 114,
		name: 'Mage\'s Ballad',
		icon: iconUrl(2602),
		onGcd: false,
		cooldown: 120000,
		potency: 100,
	},
	ARMYS_PAEON: {
		id: 116,
		name: 'Army\'s Paeon',
		icon: iconUrl(2603),
		onGcd: false,
		cooldown: 120000,
		potency: 100,
	},
	RAIN_OF_DEATH: {
		id: 117,
		name: 'Rain Of Death',
		icon: iconUrl(2605),
		onGcd: false,
		cooldown: 15000,
		potency: 100,
		cooldownGroup: 5,
		charges: 3,
	},
	BATTLE_VOICE: {
		id: 118,
		name: 'Battle Voice',
		icon: iconUrl(2601),
		onGcd: false,
		cooldown: 120000,
		statusesApplied: ['BATTLE_VOICE'],
	},
	EMPYREAL_ARROW: {
		id: 3558,
		name: 'Empyreal Arrow',
		icon: iconUrl(2606),
		onGcd: false,
		cooldown: 15000,
		potency: 200,
	},
	THE_WANDERERS_MINUET: {
		id: 3559,
		name: 'The Wanderer\'s Minuet',
		icon: iconUrl(2607),
		onGcd: false,
		cooldown: 120000,
		potency: 100,
	},
	IRON_JAWS: {
		id: 3560,
		name: 'Iron Jaws',
		icon: iconUrl(2608),
		onGcd: true,
		speedAttribute: Attribute.SKILL_SPEED,
		potency: 100,
	},
	THE_WARDENS_PAEAN: {
		id: 3561,
		name: 'The Warden\'s Paean',
		icon: iconUrl(2609),
		onGcd: false,
		cooldown: 45000,
		statusesApplied: ['THE_WARDENS_PAEAN'],
	},
	SIDEWINDER: {
		id: 3562,
		name: 'Sidewinder',
		icon: iconUrl(2610),
		onGcd: false,
		cooldown: 60000,
		potency: 300,
	},
	PITCH_PERFECT: {
		id: 7404,
		name: 'Pitch Perfect',
		icon: iconUrl(2611),
		onGcd: false,
		cooldown: 1000,
		potency: [100, 220, 360],
	},
	TROUBADOUR: {
		id: 7405,
		name: 'Troubadour',
		icon: iconUrl(2612),
		onGcd: false,
		cooldown: 90000,
		statusesApplied: ['TROUBADOUR'],
	},
	CAUSTIC_BITE: {
		id: 7406,
		name: 'Caustic Bite',
		icon: iconUrl(2613),
		onGcd: true,
		speedAttribute: Attribute.SKILL_SPEED,
		potency: 20,
		statusesApplied: ['CAUSTIC_BITE'],
	},
	STORMBITE: {
		id: 7407,
		name: 'Stormbite',
		icon: iconUrl(2614),
		onGcd: true,
		speedAttribute: Attribute.SKILL_SPEED,
		potency: 25,
		statusesApplied: ['STORMBITE'],
	},
	NATURES_MINNE: {
		id: 7408,
		name: 'Nature\'s Minne',
		icon: iconUrl(2615),
		onGcd: false,
		cooldown: 90000,
		statusesApplied: ['NATURES_MINNE'],
	},
	REFULGENT_ARROW: {
		id: 7409,
		name: 'Refulgent Arrow',
		icon: iconUrl(2616),
		onGcd: true,
		speedAttribute: Attribute.SKILL_SPEED,
		potency: 280,
	},
	SHADOWBITE: {
		id: 16494,
		name: 'Shadowbite',
		icon: iconUrl(2617),
		onGcd: true,
		speedAttribute: Attribute.SKILL_SPEED,
		potency: 170,
	},
	BURST_SHOT: {
		id: 16495,
		name: 'Burst Shot',
		icon: iconUrl(2618),
		onGcd: true,
		speedAttribute: Attribute.SKILL_SPEED,
		potency: 220,
	},
	APEX_ARROW: {
		id: 16496,
		name: 'Apex Arow',
		icon: iconUrl(2619),
		onGcd: true,
		speedAttribute: Attribute.SKILL_SPEED,
		potency: 500,
	},
	LADONSBITE: {
		id: 25783,
		name: 'Ladonsbite',
		icon: iconUrl(2620),
		onGcd: true,
		speedAttribute: Attribute.SKILL_SPEED,
		potency: 130,
	},
	BLAST_ARROW: {
		id: 25784,
		name: 'Blast Arrow',
		icon: iconUrl(2621),
		onGcd: true,
		speedAttribute: Attribute.SKILL_SPEED,
		potency: 600,
	},
	RADIANT_FINALE: {
		id: 25785,
		name: 'Radiant Finale',
		icon: iconUrl(2622),
		onGcd: false,
		cooldown: 110000,
		statusesApplied: ['RADIANT_FINALE'],
	},
})
